<template>
  <!-- Due to CSS limitations in transitioning gradient colors, we will use a
  workaround by transition an ::after elements opacity with the target gradient. -->

  <Component
    :is="buttonOrNuxtLink"
    v-bind="to ? { to: to } : {}"
    :target="$useTarget(to, target)"
    class="gradient-transition-effect group relative z-10 flex w-fit items-center overflow-hidden rounded-sm bg-gradient-to-tr py-2 text-base font-medium after:bg-gradient-to-tr sm:py-3"
    :disabled="disabled || isLoading"
    :class="[
      $slots.icon ? 'px-5' : 'px-8',
      isSecondary
        ? 'from-blue-100/50 to-blue-100/20 text-blue-800 after:from-blue-100/60 after:to-blue-100/30'
        : 'from-blue-800 to-blue-500 text-white after:from-blue-dark after:to-blue-500',
      disabled
        ? 'cursor-default !from-[#95a5a6] !to-[#95a5a6] after:!from-[#95a5a6] after:!to-[#95a5a6]'
        : '',
    ]"
  >
    <slot name="icon" />
    <!-- LINK ICONS -->
    <span :class="{ 'flex gap-3 leading-[1.3rem]': $isExternalUrl(to) }">
      <slot />
      <ExternalLinkIcon v-if="$isExternalUrl(to)" class="mb-1 w-5 shrink-0" />
    </span>
    <!-- SPINNER ICON -->
    <div class="transition-[width]" :class="isLoading ? 'w-7' : 'w-0'">
      <transition name="fade">
        <div v-if="isLoading">
          <SpinIcon class="ml-4 size-4 animate-spin text-blue-100 sm:size-5" />
        </div>
      </transition>
    </div>
  </Component>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
  target: { type: String, default: null },
  isSecondary: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
})

// determine whether to load NuxtLink or button
const buttonOrNuxtLink = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }

  return 'button'
})
</script>
